import {handleResponse, authHeader} from './helpers';
export const AuditService = {
    list
}

const urlBase = "/api/audit/";

// Returns array of audit log
function list(pageNumber){
    const url = urlBase + 'list/' + pageNumber;
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return JSON.parse(data.body);
        })
}
