import {handleResponse, authHeader} from './helpers';
export const CFAService = {
    add,
    list,
    process,
}

const urlBase = "/api/customer/";


function list()
{
    const url = urlBase + 'list/all';
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data=>
            {
                if(data.statusCode===200)
                {
                    return JSON.parse(data.body);
                }
                return null;
            });
}

function add(cfa){
    const url = urlBase + 'add';
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(cfa)
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            if(data.statusCode!==200){
                throw new Error("Could not add customer: " + data.errorMessage)
            }
            return JSON.parse(data.body);
        })
}

function process(){
    const url = urlBase + 'process';
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return JSON.parse(data.body);
        })
}