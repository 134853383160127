import {handleResponse, authHeader} from './helpers';
import { AuthenticationService } from '../services';
export const BatchService = {
    list,
    getActive,
    submit,
    cancelSubmit,
    rollback,
    processBatches,
    getPartsInBatch,
}

const urlBase = "/api/batches/";

// Returns array of Batches
function list(pageNumber){
    var url = '';
    if(pageNumber)
        url = urlBase + 'list?pageNumber=' + pageNumber;
    else 
        url = urlBase + 'list';
    return fetch(url)
        .then(handleResponse)
        .then(data => {
            return JSON.parse(data.body);
        })
}

function getPartsInBatch(batchId){
    const url = urlBase + batchId + '/parts';
    return fetch(url)
        .then(handleResponse)
        .then(data => {
            return JSON.parse(data.body);
        })
}

function getActive(){
    const url = urlBase + 'active';
    return fetch(url)
        .then(handleResponse)
        .then(data => {
            return JSON.parse(data.body);
        })
}

// submits a batch:
function submit(batchId, user){
    const url = urlBase + batchId + '/submit';
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            submittedByUserId: user.UserId
        })
    }

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data=> {
            // no data, expect a 204.
            return data;
        });
}

// cancel submits a batch:
function cancelSubmit(batchId){
    const url = urlBase + batchId + '/cancelSubmit';
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    }

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data=> {
            // no data, expect a 204.
            return data;
        });
}

// rollback a batch:
function rollback(batchId){
    const url = urlBase + batchId + '/rollback';
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    }

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data=> {
            // no data, expect a 204.
            return data;
        });
}

// call process on Boomi
function processBatches(){
    const url = urlBase + "/process";
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    }

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data=> {
            if(data.statusCode===200)
            {
                console.log("Skipping because development");
            }
            return data;
        })
}
