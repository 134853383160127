import {handleResponse, authHeader} from './helpers';
export const CsvService = {
    uploadCsv,
    importRows,
    deleteAll,
    processRows,
    getValidation,
    addToBatch,
}

const urlBase = "/api/csv/";

// Returns array of audit log
function uploadCsv(filename, file){
    const formData = new FormData();
    formData.append('file', file);    

    const url = urlBase + 'upload';
    const requestOptions = {
        method: 'POST',
        body: formData,
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return JSON.parse(data.body);
        })
}

function importRows(i, rows){
    const url = urlBase + 'import';
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            StartingLineNumber: i,
            Rows: rows
        })
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return JSON.parse(data.body);
        })
}

function deleteAll(){
    const url = urlBase + 'deleteAll';
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return JSON.parse(data.body);
        })
}

function processRows(allowNewParts){
    const url = urlBase + 'processRows?allowNewParts=' + allowNewParts;
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return JSON.parse(data.body);
        })
}

function addToBatch(shouldImportNewParts){
    const url = urlBase + 'addToBatch?shouldImportNewParts=' + shouldImportNewParts;
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            shouldImportNewParts: shouldImportNewParts
        }) 
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return JSON.parse(data.body);
        })
}

function getValidation(){
    const url = urlBase + 'validationResults';
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return JSON.parse(data.body);
        })
}


