import { AuthenticationService } from "../../services";

export async function handleResponse(response) {
  if (response.status !== 200 || response.status !== 204) {
    if (response.status === 401 || response.status===403) {
      // these need to be awaited, or .then();
      return AuthenticationService.logout().then(x=>{
        window.location.reload();
        return Promise.reject("Your user session is either not authorized to perform this action or has timed out.  Please log in with a valid user to continue.");
      }) 
    }
    
    if (response.status >= 500) {
        return Promise.reject(response.status + ": The server encountered an issue, please contact support.");
    }
  }

  // if we make it to this point, we should be good to go:
  var serverResponse = await response.json();  
  if (serverResponse.statusCode === 404) {
    console.log("Server not found.  Major Error");
    return Promise.reject("Server not found.") || null;
  }

  if (serverResponse.statusCode === 400) {
    return Promise.reject(serverResponse.errorMessage);
  }
  
  if(serverResponse.statusCode===500){
    return Promise.reject("Error retrieving response.")
  }
  
  if (response.status === 200 || response.status === 204) {
    return serverResponse;
  }
  
  if (serverResponse.errorMessage == null)
    return Promise.reject("Cannot communicate with server.");
  return Promise.reject(serverResponse.errorMessage);
}
