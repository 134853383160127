import { handleResponse, authHeader } from "./helpers";
export const TaxesService = {
  add,
  list,
  process,
};

const urlBase = "/api/taxes/";

function list() {
  const url = urlBase + "list/all";
  const requestOptions = {
    method: "GET",
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data.statusCode === 200) {
        return JSON.parse(data.body);
      }
      return null;
    });
}

function add(postalCode) {
  const url = urlBase + "create/" + postalCode;
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data.statusCode !== 204) {
        throw new Error("Could not add tax: " + data.errorMessage);
      }
      return JSON.parse(data.body);
    });
}

function process() {
  const url = urlBase + "process";
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data != null) return JSON.parse(data.body);
    });
}
