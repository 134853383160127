import { handleResponse, authHeader } from "./helpers";
export const CRGService = {
  add,
  list,
  process,
  view,
  approve,
  reject,
  update,
};

const urlBase = "/api/customer/carrols/";

const CRGOverallStatusEnum = {
  0: "Submitted",
  1: "Rejected",
  2: "Approved",
  3: "Committing",
  4: "Committed",
  5: "Committed With Errors",
};
const CRGStatusEnum = {
  0: "Queued",
  1: "Committing",
  2: "Committed",
  3: "Committed With Errors",
};

function add(crg) {
  const url = urlBase + "add";
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(crg),
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data.statusCode !== 200) {
        throw new Error("Could not add customer: " + data.errorMessage);
      }
      return JSON.parse(data.body);
    });
}

function list() {
  const url = urlBase + "list/all";
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data.statusCode === 200) {
        return JSON.parse(data.body);
      }
      return null;
    });
}

function process() {
  const url = urlBase + "process";
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return JSON.parse(data.body);
    });
}

function view(locationId) {
  const url = urlBase + "view/" + locationId;
  const requestOptions = {
    method: "GET",
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return JSON.parse(data.body);
    });
}

function approve(locationId) {
  const url = urlBase + "approve/" + locationId;
  const requestOptions = {
    method: "POST",
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return null;
    });
}

function reject(locationId) {
  const url = urlBase + "reject/" + locationId;
  const requestOptions = {
    method: "POST",
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return null;
    });
}

function update(crgViewModel) {
  const url = urlBase + "update";
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(crgViewModel),
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
