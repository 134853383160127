import {authHeader, handleResponse} from './helpers';


export const UserService = {
	list,
	update,
	add,
	viewUser,
	deleteUser
}

const urlBase = "/api/user/";

function list()
{
	const url = urlBase + 'list';
	const requestOptions = {
		method: 'GET'
	}

	return fetch(url, requestOptions)
		.then(handleResponse)
		.then(data=>
			{
				if(data.statusCode===200)
					{
						return JSON.parse(data.body);
					}
						return null;
			});
}

function update(rowData)
{
	const url = urlBase + 'update';
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(rowData)
	};

	return fetch(url, requestOptions)
		.then(handleResponse)
		.then(data => {
			if(data.statusCode ===200){
				return JSON.parse(data.body);
			} else {
				return data;
			}
		})
}

function add(create)
{
	const url = urlBase + 'add';
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(create)
	};

	return fetch(url, requestOptions)
		.then(handleResponse)
		.then(data => {
			if(data.statusCode ===200){
				return JSON.parse(data.body);
			} else {
				return data;
			}
		})
}

function deleteUser(userId)
{
	const url = urlBase +'delete/'+userId;
	const requestOptions={
		method: 'DELETE',
	}
	return fetch(url, requestOptions)
		.then(handleResponse)
		.then(data=>
		{
			if(data.statusCode===200 || data.statusCode ===204)
			{
				return JSON.parse(data.body);
			}
			return null;
		});
}

function viewUser(userId)
{
	const url = urlBase +'view/'+userId;
	const requestOptions={
		method: 'GET',
	}
	return fetch(url, requestOptions)
		.then(handleResponse)
		.then(data=>
			{
				if(data.statusCode===200)
					{
						return JSON.parse(data.body);
					}
						return null;
			});
}


