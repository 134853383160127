import {AuthenticationService} from '../../services';

export function authHeader(){
    // return authorization header with jwt token
    // return {
    //     'Content-Type': 'application/json'
    // };
    
    const currentUser = AuthenticationService.currentUser;
    if(currentUser && currentUser.CurrentSession.Token) {
        return {
            Authorization: `Bearer ${currentUser.CurrentSession.Token}`,
            'Content-Type': 'application/json',
        };
    } else {
        return {};
    }
}