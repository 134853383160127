import { Route, Redirect } from "react-router-dom";
import { createHashHistory } from 'history'
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";
import { AuthenticationService } from "../../services";

import { ToastContainer } from "react-toastify";

const security_CFAPages = ["/cfa", "/customers", "/taxes"];

const security_PartsEditorPages = [
  "/parts-editor",
  "/csv",
  "/batches",
  "/audit",
];

const security_CarrolsPages = ["/crg", "/carrols"];

const PartsEditor = lazy(() => import("../../Pages/PartsEditor"));
const Batches = lazy(() => import("../../Pages/Batches"));
const StandardCostUpdaterPage = lazy(() => import("../../Pages/StandardCost"));
const Audit = lazy(() => import("../../Pages/Audit"));
const LoginPage = lazy(() => import("../../Pages/UserPages/Login"));
const CSVUploader = lazy(() => import("../../Pages/CSV"));
const CFAPage = lazy(() => import("../../Pages/CFA"));
const Customers = lazy(() => import("../../Pages/Customers"));
const UsersPage = lazy(() => import("../../Pages/Users"));
const TaxCreator = lazy(() => import("../../Pages/Taxes"));
const CarrolsRestaurantPage = lazy(() => import("../../Pages/Carrols"));
const CRGLog = lazy(() => import("../../Pages/CRGTable"));
const CRGEdit = lazy(() => import("../../Pages/CRGEdit"));
const CarrolsNotificationView = lazy(() => import("../../Pages/CarrolsNotificationView"));
const Office365Sync = lazy(() => import("../../Pages/Office365Sync/index"));


const fallback = () => (
  <div className="loader-container">
    <div className="loader-container-inner">
      <div className="text-center">
        <Loader type="ball-pulse-rise" />
      </div>
      <h6 className="mt-5">
        Loading...
      </h6>
    </div>
  </div>
);

const AppMain = () => {
  // check login status:
  if (AuthenticationService.currentUser != null) {
    if (AuthenticationService.canAccess(0)) {
      var parts = (
        <>
          <Suspense fallback={fallback()}>
            <Route path="/parts-editor" component={PartsEditor} />
          </Suspense>
            
          <Suspense fallback={fallback()}>
            <Route path="/batches" component={Batches} />
          </Suspense>
            
          <Suspense fallback={fallback()}>
            <Route path="/csv" component={CSVUploader} />
          </Suspense>

          <Suspense fallback={fallback()}>
            <Route path="/audit" component={Audit} />
          </Suspense>
        </>
      );
    }
    if (AuthenticationService.canAccess(1)) {
      var cfa = (
        <>
          <Suspense fallback={fallback()}>
            <Route path="/cfa" component={CFAPage} />
          </Suspense>

          <Suspense fallback={fallback()}>
            <Route path="/customers" component={Customers} />
          </Suspense>
          <Suspense fallback={fallback()}>
            <Route path="/taxes" component={TaxCreator} />
          </Suspense>
        </>
      );
    }

    if (AuthenticationService.canAccess(2)) {
      var users = (
        <>
          <Suspense fallback={fallback()}>
            <Route path="/users" component={UsersPage} />
          </Suspense>
        </>
      );
    }
    if (AuthenticationService.canAccess(3)) {
      var carrols = (
        <>
            
          <Suspense fallback={fallback()}>
            <Route
              path="/carrolsRestaurant"
              component={CarrolsRestaurantPage}
            />
          </Suspense>
            
            
          <Suspense fallback={fallback()}>
            <Route path="/crgEdit/:id" component={CRGEdit} />
          </Suspense>
          <Suspense fallback={fallback()}>
            <Route exact path="/crgLog" component={CRGLog} />
          </Suspense>
          <Suspense fallback={fallback()}>
            <Route path="/crgLog/:id" component={CRGLog} />
          </Suspense>
          <Suspense fallback={fallback()}>
            <Route path="/crgNotifications" component={CarrolsNotificationView} />
          </Suspense>
        </>
      );
    }
    
    if (AuthenticationService.canAccess(4)) {
      var standardCost = (
          <Suspense fallback={fallback()}>
              <Route path="/updateStandardCost" component={StandardCostUpdaterPage} />
          </Suspense>
      );
      
    }
    
    if (AuthenticationService.canAccess(5)){
        var office365Sync = (
            <Suspense fallback={fallback()}>
                <Route path="/office365Sync" component={Office365Sync}/>
            </Suspense>
        )
    }
    
  }
  if (AuthenticationService.currentUser == null) {
    var carrolsPublic = (
      <>
        <Suspense fallback={fallback()}>
          <Route
            exact
            path="/carrolsRestaurant/newRestaurant"
            component={CarrolsRestaurantPage}
          />
        </Suspense>
      </>
    );
  }

  const containsCFA = () => {
    return security_CFAPages.some((x) => window.location.href.includes(x));
  };

  const containsPartsEditor = () => {
    return security_PartsEditorPages.filter((x) => {
        window.location.href.includes(x)
        }      
    );
  };

  const containsCarrols = () => {
    return security_CarrolsPages.some((x) => window.location.href.includes(x));
  };

  const history = createHashHistory({});

  const accessByRole = (role) => {
    if (role === 0) {
      return <Redirect to="/parts-editor" />;
    } else if (role === 1) {
      return <Redirect to="/cfa" />;
    } else if (role === 2) {
      return <Redirect to="/users" />;
    } else if (role === 3) {
      return <Redirect to="/carrolsRestaurant" />;
    }else if (role === 4) {
      return <Redirect to="/updateStandardCost" />;
    } else if (role === 5) {
        return <Redirect to={"/office365Sync"}/>;
    } 
    else {
      return null;
    }
  };
  const redirect = () => {
      if(typeof AuthenticationService.currentUser === "undefined") {
          history.push({pathname: '/login'})
      }
      
    if (AuthenticationService.currentUser == null) {
      if (window.location.href.includes("/carrolsRestaurant")) {
        return <Redirect to="/carrolsRestaurant/newRestaurant" />;
      } else {
        return <Redirect to={"/login"} />;
      }
    }
    const hasRole = AuthenticationService.currentUser.Roles.length > 0;
    const firstRole = hasRole
      ? AuthenticationService.currentUser.Roles[0].Role
      : 20;
    
    if (containsPartsEditor()) {
      if (!AuthenticationService.canAccess(0)) {
        return accessByRole(firstRole);
      }
    } else if (containsCFA()) {
      if (!AuthenticationService.canAccess(1)) {
        return accessByRole(firstRole);
      }
    } else if (window.location.href.includes("/users")) {
      if (!AuthenticationService.canAccess(2)) {
        return accessByRole(firstRole);
      }
    } else if (containsCarrols()) {
      if (!AuthenticationService.canAccess(3)) {
        return accessByRole(firstRole);
      }
    } else if (window.location.href.includes("updateStandardCost")) {
      if (!AuthenticationService.canAccess(4)) {
        return <Redirect to="/parts-editor" />
      }
    } else {
      if (AuthenticationService.canAccess(0)) {
        return <Redirect to={`/parts-editor`} />;
      }
      if (AuthenticationService.canAccess(1)) {
        return <Redirect to={`/cfa`} />;
      }
      if (AuthenticationService.canAccess(2)) {
        return <Redirect to={`/users`} />;
      }
      if (AuthenticationService.canAccess(3)) {
        return <Redirect to={`/carrolsRestaurant`} />;
      }
      if (AuthenticationService.canAccess(4)) {
        return <Redirect to={`/updateStandardCost`} />;
      }
      if (AuthenticationService.canAccess(5)){
          return <Redirect to={"/office365Sync"}/>;
      }
    }
  };

  return (
    <Fragment>
      {parts}
      {cfa}
      {users}
      {carrols}
      {carrolsPublic}
      {standardCost}
      {office365Sync}
      <Suspense fallback={fallback()}>
        <Route path="/login" component={LoginPage} />
      </Suspense>

      {redirect()}
      {AuthenticationService.currentUser == null ? (
        <Route exact path="/" render={() => <Redirect to="/login" />} />
      ) : (
        redirect()
      )}

      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
