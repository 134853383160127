import {handleResponse, authHeader} from './helpers';

export const PartsService = {
    listQuery,
    update,
    add,
    getStandardCostData,
    updateStandardCost,
    exportStandardCostDetails,
    getList
}

const urlBase = "/api/parts/";

function listQuery(value, pageNumber)
{
    const url = urlBase + 'list/query?filterText=' + value + '&page=' + pageNumber;
    const requestOptions = {
        method: 'GET'
    }

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data=>
            {
                if(data.statusCode===200)
                {
                    return data;
                }
                return null;
            });
}

function update(rowDataValueObject)
{
    const viewModel = partValueObjectToViewModel(rowDataValueObject);
    const url = urlBase + 'update';
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(viewModel)
    };

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        })
}

function partValueObjectToViewModel(valueObject){
    return {
        PartCode: valueObject.PartCode,
        Description: valueObject.Description,
        ManufacturerId: valueObject.ManufacturerId,
        Manufacturer: valueObject.Manufacturer,
        ProductLineId: valueObject.ProductLineId,
        ProductLine: valueObject.ProductLine,
        //has vendorId, vendorPartNumber, isPrimary, and cost
        Vendors: valueObject.Vendors
    }
}

function add(rowDataValueObject)
{
    const viewModel = partValueObjectToViewModel(rowDataValueObject);
    const url = urlBase + 'add';
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(viewModel)
    };

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        })
}

function getStandardCostData(threshold, page)
{
    const url = urlBase + `getStandardCostData/${threshold}/${page}`;
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return JSON.parse(data.body);
        })
}

function updateStandardCost(threshold)
{
    const url = urlBase + `updateIncorrectStandardCosts/${threshold}`;
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return JSON.parse(data.body);
        })
}

function exportStandardCostDetails(threshold) {

    const url = urlBase + `exportStandardCostDetails/${threshold}`;
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(url, requestOptions)
        .then(res => res.blob())
        .then(data => {
            return data
        })
}


async function getList(type) {
    if (type === undefined)
        return;
    const storageName = type + 'List';
    const storedList = window.localStorage.getItem(storageName.toString());
    if (storedList != null) {
        const localList = JSON.parse(storedList);
        if (localList.expireTime !== undefined && (new Date(localList.expireTime) >= new Date())) {
            return localList.list;
        }
    }

    return await fetch('/api/selectList/' + type)
        .then(response => response.json())
        .then(json => {
            if (json.statusCode === 200) {
                const returnData = JSON.parse(json.body);
                window.localStorage.setItem(storageName.toString(),
                    JSON.stringify(
                        {
                            expireTime: new Date().addHours(2),
                            list: returnData
                        }
                    ));
                return returnData;
            } else if(json.statusCode === 500) {
                console.log('Error, Internal Server Error. Unable to retrieve data from the backend.');
            } 
            else if(json.statusCode === 400){
                console.log("The request could not be understood by the server due to incorrect syntax.")
            } 
            else{
                //We don't expect to show an error to the user for this fetch. This is for dropdowns for productline, manufacturer, and vendor lists.
                console.log("Error");
            }
        });
}