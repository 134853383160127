import { handleResponse } from "./helpers";

//var localUser = JSON.parse(localStorage.getItem('currentUser'));

export const AuthenticationService = {
  login,
  logout,
  canAccess,
  get currentUser() {
    var cookie = getCookie("CurrentUser");
    if (cookie === "") return;
    var localUser = JSON.parse(cookie);
    return localUser;
  },
};

function canAccess(role) {
  const roles = this.currentUser.Roles;
  
  if (roles.length === 0) return false;

  const filteredRole = roles.filter((x) => x.Role === role);
  if (filteredRole.length === 0) return false;

  // first filteredRole:
  return filteredRole[0].CanAccess;
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function delete_cookie(name) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

// This method does not work because microsoft MFA.  We need to "redirect" to microsoft instead.
function login() {
  var redirect = "https://partsupdater.ion247.com/";
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    redirect = "http://localhost:5001/";
  }
  window.location.href =
    "https://login.microsoftonline.com/a0de87f9-7143-4259-95f6-0fa3f383cbf0/oauth2/v2.0/authorize?" +
    "client_id=068c3c78-cf9e-4b37-bc23-aa005624cef6&" +
    "scope=openid%20user.read%20profile%20email&" +
    "response_type=id_token%20token&nonce=xsy&" +
    "response_mode=form_post&" +
    "redirect_uri=" +
    redirect +
    "api/user/microsoftLogin";
}

function logout() {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };
  return fetch("/api/user/logout", requestOptions)
    .then(handleResponse)
    .then((data) => {
     
      // remove cookie:
      delete_cookie("CurrentUser");
      return data;
    });
}
